import { PageProps } from "pages/_app";
import { useEffect, useState } from "react";
import { useRouter } from "next/router";
import { useAtom } from "jotai";
import { currentLocationAtom, routeAtom, titleAtom } from "store/global";
import Head from "next/head";
import { ROUTE } from "@constants/route";

function Entrypoint(_: PageProps) {
  const [title] = useAtom(titleAtom);
  const [currentPage, setCurrentPage] = useAtom(routeAtom);
  // const [isNavi, setIsNavi] = useState(false);
  // const [position, setPosition] = useState<GeolocationPosition | null>(null);
  const router = useRouter();
  const entryType = router.query.type as string;
  const address1 = router.query.address1 as string;
  const address2 = router.query.address2 as string;
  const address3 = router.query.address3 as string;

  useEffect(() => {
    if (!router.isReady) return;
    movePage();
  }, [router.isReady]);

  const movePage = () => {
    const page = router.pathname;
    setCurrentPage(page);
    if (!!entryType) {
      // 팜모닝앱을 통한 진입
      if (!!address1 && !!address2 && !!address3) {
        // 주소가 있을때
        if (currentPage === ROUTE.LIST && page === ROUTE.ENTRYPOINT) {
          // 리스트 페이지에서 빈페이지로 넘어왔을때 (뒤로가기)
          router.back();
        }
        // else if (currentPage === ROUTE.LIST && page === ROUTE.ENTRYPOINT) {
        //   router.push({
        //     pathname: ROUTE.LIST,
        //     query: {
        //       type: entryType,
        //       address1: address1,
        //       address2: address2,
        //       address3: address3,
        //     },
        //   });
        // }
        // 그 외, 리스트 진입
        router.push({
          pathname: ROUTE.LIST,
          query: {
            type: entryType,
            address1: address1,
            address2: address2,
            address3: address3,
          },
        });
        //@ts-ignore
      } else if (window.ReactNativeWebView) {
        if (currentPage === ROUTE.ADDRESS && page === ROUTE.ENTRYPOINT) {
          router.back();
        } else if (currentPage === ROUTE.LIST && page === ROUTE.ENTRYPOINT) {
          router.back();
        }
        if (!!address1 && !!address2 && !!address3) {
          router.push({
            pathname: ROUTE.LIST,
            query: {
              type: entryType,
              address1: address1,
              address2: address2,
              address3: address3,
            },
          });
        } else {
          router.push({
            pathname: ROUTE.LIST,
            query: {
              type: entryType,
            },
          });
        }
      } else {
        if (currentPage === ROUTE.ADDRESS && page === ROUTE.ENTRYPOINT) {
          router.back();
        } else if (currentPage === ROUTE.LIST && page === ROUTE.ENTRYPOINT) {
          router.back();
        } else {
          router.push({ pathname: ROUTE.ADDRESS, query: { type: entryType } });
        }
      }
    } else {
      router.replace(ROUTE.LIST);
    }
  };

  // useEffect(() => setPreviousPath, [router.asPath]);
  //
  // const setPreviousPath = () => {
  //   if (sessionStorage) {
  //     const prevPath = sessionStorage.getItem("currentPath");
  //     sessionStorage.setItem("prevPath", prevPath ? prevPath : "/");
  //     sessionStorage.setItem("currentPath", router.pathname);
  //   }
  // };

  // const next = () => {
  //   if (isNavi) router.push("/list");
  //   else router.push("/address");
  // };
  //
  // const checkButtonDisabled = () => {
  //   return !position;
  // };
  //
  // useEffect(() => {
  //   if ("geolocation" in navigator) {
  //     navigator.geolocation.getCurrentPosition(po => {
  //       setPosition(po);
  //       if (po) setIsNavi(true);
  //       else setIsNavi(false);
  //     });
  //   } else {
  //     setIsNavi(false);
  //   }
  // }, []);

  return (
    <>
      <Head>
        <title>{title}</title>
      </Head>
    </>
  );
}

export default Entrypoint;
